import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { nanoid } from 'nanoid'
import { StaticImage } from 'gatsby-plugin-image'

import Headings from '../components/Common/Headings'
import ColoredHeading from '../components/Common/ColoredHeading'
import CardGame from '../components/Common/Card/CardGameGames'

import Seo from '../components/Seo'

import { useContent } from '../hooks/useContent'
import { getThumbnail } from '../helpers/thumbnail'

import ChevronRightIcon from '../assets/images/icons/svg/icon-right-chevron.svg'

const Games = ({ data }) => {
  const content = useContent(data, 'games')

  return (
    <>
      <Seo title={content.title} slug={'/games'} />
      <div className="w-full mt-10 lg:mt-20">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-10">
          <div className="lg:col-span-8 lg:col-start-3 text-center grid gap-4 lg:gap-8">
            <Headings>
              <ColoredHeading text={content.heading} />
            </Headings>

            <p className="typo-medium-16 text-gray">{content.subHeading}</p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-5 lg:gap-10 mt-[60px]">
          {content?.games?.map(
            ({
              name,
              summary,
              googlePlayUrl,
              appStoreUrl,
              thumbnail,
              numberOfDownload,
              slogan,
            }) => (
              <CardGame
                key={nanoid()}
                name={name}
                summary={summary}
                appStoreUrl={appStoreUrl}
                googlePlayUrl={googlePlayUrl}
                numberOfDownload={numberOfDownload}
                slogan={slogan}
                thumbnail={getThumbnail(content, thumbnail)}
              />
            )
          )}
        </div>

        <div className="w-full flex flex-col-reverse lg:grid lg:grid-cols-2 md:gap-5 bg-gray-light mt-10 lg:mt-20 rounded-lg">
          <div className="flex flex-col justify-center items-center lg:items-start mx-[16px] lg:mx-0 lg:p-20">
            <Headings level={2}>
              <ColoredHeading text={content.callToAction.heading} />
            </Headings>
            <p className="text-center lg:text-left mt-5 mx-[15px] lg:mx-0 typo-medium-14 md:typo-medium-16 text-secondary">
              {content.callToAction.summary}
            </p>
            <Link
              to={content.callToAction.link.url}
              className="group w-auto flex items-center mt-5 typo-bold-16 text-secondary mb-10 lg:mb-0"
            >
              <p>{content.callToAction.link.title}</p>
              <span className="group-hover:translate-x-3 transition-transform">
                <ChevronRightIcon />
              </span>
            </Link>
          </div>
          <div className="justify-self-center mx-auto lg:justify-self-end lg:mr-3">
            <StaticImage
              src="../assets/images/illustration/talk-img.png"
              alt="contact us"
              width={320}
              height={320}
              layout="fixed"
              quality={100}
              placeholder="blurred"
              className="px-[60px]"
              loading='eager'
            />
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query ($content: String = "games", $gamesContent: String = "game") {
    allMarkdownRemark(filter: { frontmatter: { content: { eq: $content } } }) {
      edges {
        node {
          id
          frontmatter {
            title
            subHeading
            heading
            callToAction {
              heading
              summary
              link {
                title
                url
              }
            }
          }
        }
      }
    }
    games: allMarkdownRemark(
      filter: { frontmatter: { content: { eq: $gamesContent } } }
      sort: { order: ASC, fields: frontmatter___index }
    ) {
      edges {
        node {
          frontmatter {
            name
            summary
            thumbnail
            googlePlayUrl
            appStoreUrl
            numberOfDownload
            slogan
            index
          }
        }
      }
    }
    images: allFile(filter: { relativeDirectory: { eq: "images/games" } }) {
      edges {
        node {
          relativePath
          name
          relativeDirectory
          childImageSharp {
            gatsbyImageData(
              width: 120
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              quality: 100
              breakpoints: [640, 768, 1024, 1280]
            )
          }
        }
      }
    }
  }
`

export default Games
