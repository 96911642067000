import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Badge = ({ numberOfDownload = null, slogan = null }) => {
  return (
    <div className="lg:w-40 lg:h-40 absolute top-[-40px] right-[-15px] lg:right-[-60px] bg-transparent rounded-full w-40 h-40">
      <StaticImage
        src="../../../assets/images/icons/svg/Badge.svg"
        alt="number of download badge"
        quality={100}
        placeholder="blurred"
        loading='eager'
      />
      <div className="absolute top-[19px] right-[55px] bg-transparent w-[85px] h-[85px] rounded-full flex flex-col justify-center items-center fix-glitch">
        {numberOfDownload && (
          <>
            <div className='text-custom-gradient'>
            <p className="text-[18px] olive-days text-center">{numberOfDownload}</p>
            <p className="text-[10px]  olive-days text-center">Downloads</p>
            </div>
          </>
        )}
        {slogan && (
            <p
              className="text-[9px] olive-days text-center"
              dangerouslySetInnerHTML={{ __html: slogan }}
            />
        )}
      </div>
    </div>
  )
}

export default Badge
