export const getThumbnail = (data, thumbnail) => {
  if (!String(thumbnail).startsWith('http')) {
    const result = data.images?.edges.filter(item =>
      item.node.relativePath?.includes(thumbnail)
    )
    return result[0].node.childImageSharp.gatsbyImageData
  }

  return thumbnail
}
